import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import './hpcard.css'
import { Link } from 'gatsby'

export default () => (
    <StaticQuery
        query={graphql` 
            query SpiritcardQuery {
                allContentfulSpiritCard(sort: {order: ASC, fields: title}) {
                    edges {
                        node {
                            id
                            title
                            link                            
                            image {
                                fluid (maxWidth: 1200, quality: 85) {
                                    src
                                    ...GatsbyContentfulFluid
                                }
                                title
                            }
                        }
                    }
                }
            }
        `}

      render={data => (
        <>
            {data.allContentfulSpiritCard.edges.map(edge => (

                <Link to={"../" + edge.node.link}>
                    <div className="hp-card">
                        <img src={edge.node.image.fluid.src}  alt={edge.node.image.title} />
                            <h3>{edge.node.title}</h3>
                    </div>
                </Link>
            
            ))}
        </>
      )}
    />
  )
  

 /*old non queried component

  const HpCard = props => (
    <Link to={props.url}>
        <div className="hp-card">
            <img src={props.image}/>
                <h3>{props.title}</h3>
        </div>
    </Link>
)

export default HpCard
*/