import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import HpCard from "../components/hpcard"


const LearnSpirits = () => (
  <Layout>
    <SEO title="Spirit Collections" />
    <PostHero
        title="Spirit Collections"
        image={require('../images/roberto-carlos-roman-FOVDCYvO_vw-unsplash.jpg')}
    />

    <div className="hub-wrapper">

      
      <div className="hub-section">
        <div className="hub-section-title center neg-margin">
            <h2>The Spirits</h2>
        </div>

    
        <div className="hp-cards" id="cards" name="cards">

        <HpCard />

          {/*
          <HpCard
            title="Brandy"
            image={require('../images/nacho-dominguez-argenta-F_ilCik66Hg-unsplash.jpg')}
            url="/brandy"
          />
          <HpCard
            title="Gin"
            image={require('../images/elizabeth-explores-qdfOJ9lk63w-unsplash.jpg')}
            url="/gin"
          />
          <HpCard
            title="Rum"
            image={require('../images/david-clode-h1Phiix3uxM-unsplash.jpg')}
            url="/rum"
          />
          <HpCard
            title="Tequila"
            image={require('../images/tequila-hp-1000.jpg')}
            url="/tequila"
          />
          <HpCard
            title="Whiskey"
            image={require('../images/emma-van-sant-JkfNpgk8oNw-unsplash.jpg')}
            url="/whiskey"
          />
          <HpCard
            title="Vodka"
            image={require('../images/prince-abid-iY1k44Aa4uQ-unsplash.jpg')}
            url="/vodka"
          />
          */}

        </div> 
      </div>  
      
    </div>
    
  </Layout>
)

export default LearnSpirits